import { MapRef } from "react-map-gl";
import * as turf from "@turf/turf";
import { useEffect, useRef } from "react";
import "maplibre-gl/dist/maplibre-gl.css";
import { LngLatLike } from "mapbox-gl";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useGetCurrentUser } from "./useGetCurrentUser";
import useIsJoinedToProject from "./useIsJoinedToProject";
import { setIsMapLoaded, setMapSize } from "../redux/api/mapSlice";
import { selectSpot, setCategorySelected } from "../redux/api/spotsSlice";
import {
	setViewState,
	setAddPlace,
	setEditPlace,
} from "../redux/api/locationSlice";
import {
	MapLayerMouseEvent,
	ViewStateChangeEvent,
} from "react-map-gl/dist/esm/types";
import { setTokens, setOpenJoinProjectWindow } from "../redux/api/userSlice";
import { setCickOutsideArea } from "../redux/api/projectCardsSlice";

const predefinedArea = false;

const useHandleMap = (handleViewModOpen: () => void) => {
	const dispatch = useAppDispatch();

	const { user } = useGetCurrentUser();

	const currTokens = useAppSelector((state) => state.user.tokens);
	const viewState = useAppSelector((state) => state.location.viewState);
	const selectedLocation = useAppSelector((state) => state.location);
	const markerLocation = useAppSelector(
		(state) => state.location.markerLocation
	);
	const addingPlace = useAppSelector((state) => state.location.addPlace);
	const editPlace = useAppSelector((state) => state.location.editPlace);
	const isJoinedToProject = useIsJoinedToProject();
	const categorySelected = useAppSelector(
		(state) => state.spots.categorySelected
	);
	const region = useAppSelector((state) => state.spots.selectedDistrict);
	const mapRef = useRef<MapRef>(null);
	const mapContainerRef = useRef<HTMLDivElement>(null);

	const currentLang = localStorage.getItem("lang");

	const mapParams = {
		onLoad: () => dispatch(setIsMapLoaded(true)),
		onClick: (e: MapLayerMouseEvent<mapboxgl.Map>) => handleMapClick(e),
		viewState: { ...viewState },
		mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
		mapStyle: "mapbox://styles/mapbox/streets-v11",
		onMove: (e: ViewStateChangeEvent<mapboxgl.Map>) =>
			dispatch(setViewState(e.viewState)),
		attributionControl: false,
	};
	const onMapLoad = () => dispatch(setIsMapLoaded(true));
	const onMapMove = () => (e: ViewStateChangeEvent<mapboxgl.Map>) =>
		dispatch(setViewState(e.viewState));

	const handleMapClick = (e: any) => {
		if (e.originalEvent.target.ariaLabel === "Map") {
			if (region && region.polygon && predefinedArea) {
				const point = turf.point([e.lngLat.lng, e.lngLat.lat]);
				const formattedCoordinates = region.polygon.geometry.coordinates;

				const polygon = turf.polygon(formattedCoordinates);
				const isInside = turf.booleanPointInPolygon(point, polygon);

				if (!isInside) {
					e.preventDefault();
					dispatch(setCickOutsideArea(true));
					return;
				} else {
					dispatch(setCickOutsideArea(false));
				}
			}

			if (!user) {
				handleViewModOpen();
				return;
			}

			if (!isJoinedToProject) {
				dispatch(setOpenJoinProjectWindow(true));
				return;
			}

			if (addingPlace && !categorySelected) {
				dispatch(setAddPlace(null));
				dispatch(setEditPlace(false));
				if (currTokens) dispatch(setTokens(currTokens + 1));
				return;
			}

			if (editPlace) {
				dispatch(setEditPlace(false));
				dispatch(selectSpot(null));
				dispatch(setCategorySelected(false));
				return;
			}

			dispatch(selectSpot(null));
			dispatch(setAddPlace([e.lngLat.lng, e.lngLat.lat]));

			// if (currTokens) dispatch(setTokens(currTokens - 1));
		}
	};

	const resizeObserver = new ResizeObserver((entries) => {
		const height = entries[0].target.clientHeight;
		const width = entries[0].target.clientWidth;
		dispatch(setMapSize({ height, width }));
	});
	if (mapContainerRef.current) {
		resizeObserver.observe(mapContainerRef.current);
	}

	useEffect(() => {
		if (!mapRef.current) return;
		mapRef.current.flyTo({
			center: selectedLocation.center,
			speed: 1.2,
			curve: 1,
			zoom: selectedLocation.zoom,
		});
	}, [selectedLocation.center]);

	useEffect(() => {
		if (mapRef.current && currentLang && mapRef.current.loaded()) {
			const map = mapRef.current?.getMap();
			map.setLayoutProperty("country-label", "text-field", [
				"get",
				`name_${currentLang}`,
			]);
		}
	}, [mapRef.current, currentLang, mapRef.current?.loaded()]);

	useEffect(() => {
		if (!mapRef.current || !markerLocation) return;
		mapRef.current.flyTo({
			center: markerLocation,
			speed: 1.2,
			curve: 1,
			zoom: selectedLocation.zoom,
		});
	}, [markerLocation]);

	const zoomHandler = (zoom: boolean) => {
		mapRef.current?.flyTo({
			center: [viewState.longitude, viewState.latitude],
			duration: 500,
			zoom: zoom ? viewState.zoom + 1 : viewState.zoom - 1,
		});
	};
	const isInViewPort = (lng: number, lat: number) => {
		return !!mapRef.current?.getBounds().contains([lng, lat] as LngLatLike);
	};
	return {
		mapHandler: {
			mapRef,
			mapContainerRef,
			handleMapClick,
			zoomHandler,
			isInViewPort,
			markerLocation,
			mapParams,
			onMapLoad,
			onMapMove,
		},
	};
};

export default useHandleMap;
