import { FC } from "react";
import { paths } from "../../constants";
import { addProjectImage } from "../../assets/shared";
import ProjectCard from "../../components/projects/ProjectCard";

const withExtraComponent = (WrappedComponent: FC, styles: string) => {
	return (props: any) => {
		const extraData = {
			id: "newProject",
			linkTo: paths.newProject,
			image: addProjectImage,
			name: "new-project",
			title: {
				de: "Starte dein Projekt!",
				en: "Start a new project!",
			},
			description: {
				en: "If you have a good idea to help your community, you can use Parry to involve all your members in mapping, organising and ranking priorities to establish where change is needed most.",
				de: "Du hast eine gute Idee und willst, dass sie so vielen Menschen wie möglich zugutekommt? Beziehe deine Mitglieder und Bürgerinnen und Bürger ganz einfach in die Planung mit ein — und finde mit unserer Karte heraus, wo der Bedarf am größten ist.",
			},
			reactions: false,
		};

		return (
			<div className={styles}>
				<WrappedComponent {...props} />
				<ProjectCard
					id={extraData.id}
					key={extraData.id}
					linkTo={extraData.linkTo}
					image={extraData.image} // Replace with the desired image path
					title={extraData.name}
					description={extraData.description}
					reactions={extraData.reactions}
				/>
			</div>
		);
	};
};

export default withExtraComponent;
