import { useEffect, useState } from "react";
import ReactMapGL, { Source, Layer } from "react-map-gl";
import * as turf from "@turf/turf";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { DocumentData } from "@firebase/firestore";
import { setViewState, setAddPlace } from "../../../redux/api/locationSlice";
import { mapboxAccessToken, mapStyle } from "../../../constants";
import useHandleMap from "../../../hooks/useHandleMap";
import MapMarker from "../../../shared/MapMarker";
import MapSpot from "../../../shared/MapSpot";
import Zoom from "./Zoom";
import "maplibre-gl/dist/maplibre-gl.css";

import { LngLatLike } from "mapbox-gl";
import useRestrictions from "../../../hooks/useRestrictions";
import ViewModModal from "../../auth/ViewModModal";
import { regions2 as regions } from "../../../constants";

import { getMaxBounds } from "../../../utils/getMaxBounds";
import { setCickOutsideArea } from "../../../redux/api/projectCardsSlice";

const predefinedArea = false;

interface Region {
	name: string;
	polygon?: GeoJSON.Feature<GeoJSON.Polygon, GeoJSON.GeoJsonProperties>;
}

const ParryMap = () => {
	const dispatch = useAppDispatch();
	const viewState = useAppSelector((state) => state.location.viewState);
	const sortedSpots = useAppSelector((state) => state.spots.sortedSpots);
	const detailedView = useAppSelector(
		(state) => state.elementsView.spotCardView
	);
	const selectedSpot = useAppSelector((state) => state.spots.selectedSpot);
	const addingPlace = useAppSelector((state) => state.location.addPlace);
	const region = useAppSelector((state) => state.spots.selectedDistrict);

	useEffect(() => {
		if (addingPlace && selectedSpot) {
			dispatch(setAddPlace(null));
		}
	}, [selectedSpot]);

	useEffect(() => {
		if (region.polygon && region.name !== "All" && region.name !== "Berlin") {
			// Assuming setSelectedRegion accepts GeoJSON feature

			if (mapHandler.mapRef.current) {
				const map = mapHandler.mapRef.current.getMap();
				const bbox = turf.bbox(region.polygon);
				const fitBoundsParams = [bbox[0], bbox[1], bbox[2], bbox[3]] as [
					number,
					number,
					number,
					number
				];
				map.fitBounds(fitBoundsParams);
			}
		}
	}, [region]);

	const {
		isViewMod,
		hangleSignIn,
		hangleLogIn,
		handleViewModClose,
		handleViewModOpen,
	} = useRestrictions();

	const { mapHandler } = useHandleMap(handleViewModOpen);

	const onMarkerDragEnd = (newLngLat: [number, number]) => {
		if (region && region.polygon && predefinedArea) {
			const point = turf.point(newLngLat);
			const formattedCoordinates = region.polygon.geometry.coordinates;

			const polygon = turf.polygon(formattedCoordinates);
			const isInside = turf.booleanPointInPolygon(point, polygon);

			if (!isInside) {
				dispatch(setCickOutsideArea(true));
				return;
			} else {
				dispatch(setCickOutsideArea(false));
				dispatch(setAddPlace([newLngLat[0], newLngLat[1]]));
			}
		} else {
			dispatch(setAddPlace([newLngLat[0], newLngLat[1]]));
		}
	};

	return (
		<div ref={mapHandler.mapContainerRef} className="w-full h-full">
			<ReactMapGL
				onLoad={mapHandler.onMapLoad}
				onClick={mapHandler.handleMapClick}
				ref={mapHandler.mapRef}
				{...viewState}
				style={{ width: "100%", height: "100%" }}
				mapboxAccessToken={mapboxAccessToken}
				mapStyle={mapStyle}
				onMove={(e) => dispatch(setViewState(e.viewState))}
				attributionControl={false}
				cursor={"default"}
				maxBounds={
					region.polygon &&
					region.polygon.geometry.coordinates &&
					predefinedArea
						? getMaxBounds(region.polygon.geometry.coordinates)
						: [
								[-180, -90],
								[180, 90],
						  ]
				} // make map bounds to the map
			>
				{sortedSpots?.map((spot: DocumentData) => {
					const isVisible = mapHandler.isInViewPort(
						spot.latitude,
						spot.longitude
					);
					// console.log(spot);
					return (
						<MapSpot
							key={spot.id}
							spot={spot}
							zoom={viewState.zoom}
							isVisible={isVisible}
						/>
					);
				})}
				{mapHandler.markerLocation && (
					<MapMarker
						center={mapHandler.markerLocation}
						isSearchingPlace={true}
					/>
				)}
				{addingPlace && (
					<MapMarker
						center={addingPlace}
						isSearchingPlace={false}
						onMarkerDragEnd={onMarkerDragEnd}
					/>
				)}
				{region.polygon &&
					region.name !== "All" &&
					region.name !== "Berlin" && (
						<>
							{predefinedArea && (
								<Source
									id="outside-region-mask"
									type="geojson"
									data={{
										type: "FeatureCollection",
										features: [
											{
												type: "Feature",
												geometry: {
													type: "Polygon",
													// Define the outer polygon (e.g., map bounds)
													coordinates: [
														[
															[-180, 90], // Top-left corner
															[180, 90], // Top-right corner
															[180, -90], // Bottom-right corner
															[-180, -90], // Bottom-left corner
															[-180, 90], // Closing the outer bounds
														],
														...region.polygon.geometry.coordinates, // Subtract the selected region polygon
													],
												},
											},
										],
									}}
								>
									<Layer
										id="outside-region-fill"
										type="fill"
										paint={{
											"fill-color": "#C4C4C4", // Color for the outside area
											"fill-opacity": 0.5, // Adjust opacity as needed
										}}
									/>
								</Source>
							)}

							<Source id="selected-region" type="geojson" data={region.polygon}>
								<Layer
									id="selected-region-fill"
									type="fill"
									paint={{ "fill-color": "#088", "fill-opacity": 0 }}
								/>
								<Layer
									id="selected-region-border"
									type="line"
									paint={{
										"line-color": "#717171",
										"line-width": 2,
										"line-dasharray": [2, 2],
									}}
								/>
							</Source>
						</>
					)}
				<Zoom zoomHandler={mapHandler.zoomHandler} />
			</ReactMapGL>
			<ViewModModal
				isOpen={isViewMod}
				handleClose={handleViewModClose}
				hangleSignIn={hangleSignIn}
				hangleLogIn={hangleLogIn}
			/>
		</div>
	);
};
export default ParryMap;
