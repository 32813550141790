import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { Project } from "../../interfaces";
import { RootState } from "../app/store";

interface initialState {
	selectedProjectLink: null | string;
	selectedProjectId: null | string;
	projects: Project[];
	clickOutsideArea: boolean;
}

const initialState: initialState = {
	selectedProjectLink: null,
	selectedProjectId: null,
	projects: [],
	clickOutsideArea: false,
};

export const projectCardsSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setSelectedProjectLink: (state, action: PayloadAction<string | null>) => {
			state.selectedProjectLink = action.payload;
		},
		setSelectedProjectId: (state, action: PayloadAction<string | null>) => {
			state.selectedProjectId = action.payload;
		},
		setProjects: (state, action: PayloadAction<Project[]>) => {
			state.projects = action.payload;
		},
		setCickOutsideArea: (state, action: PayloadAction<boolean>) => {
			state.clickOutsideArea = action.payload;
		},
	},
});

export const {
	setSelectedProjectLink,
	setSelectedProjectId,
	setProjects,
	setCickOutsideArea,
} = projectCardsSlice.actions;
export default projectCardsSlice.reducer;

export const currentProject = createSelector(
	(state: RootState) => state.projectCards.selectedProjectId,
	(state: RootState) => state.projectCards.projects,
	(projetctId, projects: Project[]) => {
		if (projetctId && projects.length > 0) {
			return projects.find((item: Project) => item.id === projetctId);
		} else {
			return undefined;
		}
	}
);
