import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextSection from "../../shared/TextSection";
import Reactions from "./Reactions";
import CardWrapper from "./CardWrapper";
import { paths } from "../../constants";
import { ProjectText } from "../../interfaces";

interface Props {
	id: string;
	linkTo: string;
	image: string;
	title: ProjectText | string;
	description: ProjectText;
	reactions: boolean;
}

const ProjectCard = ({
	linkTo,
	image,
	title,
	description,
	reactions,
	id,
}: Props) => {
	const [currLang, setCurrLang] = useState<"de" | "en">("de");

	const lang = localStorage.getItem("lang");

	useEffect(() => {
		if (lang) {
			setCurrLang(lang as "de" | "en");
		} else {
			setCurrLang("de");
		}
	}, [currLang, lang]);

	return (
		<>
			<CardWrapper
				linkTo={linkTo === paths.newProject ? linkTo : paths.project(linkTo)}
				id={id}
			>
				<div className={`h-full w-full flex flex-col justify-start`}>
					<div className="mb-4">
						<img src={image} alt="project-logo" />
					</div>
					<div className="px-4 mb-4">
						<TextSection
							title={typeof title === "string" ? title : title[currLang]}
						>
							{typeof description === "string"
								? description
								: description[currLang]}
						</TextSection>
						<div className="mb-4"></div>
						{reactions && <Reactions />}
					</div>
				</div>
			</CardWrapper>
		</>
	);
};

export default ProjectCard;
