import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { useAppDispatch } from "../redux/hooks";
import {
	setTokens,
	setHasUserVerified,
	setIsJoinedToProjects,
} from "../redux/api/userSlice";

interface IUserInfo {
	userName: string;
	photoURL: string;
	about: string | null;
	interests: string[] | null;
	spot: {
		longitude: number;
		latitude: number;
		zoom: number;
	} | null;
	tokens: number;
	isJoinedToProjects: string[];
	isUserVerified: boolean;
}

const useUserProfileInfo = (userId: string, updateOnRerender?: boolean) => {
	const [userInfo, setUserInfo] = useState<IUserInfo>({
		userName: "",
		photoURL: "",
		about: null,
		interests: null,
		spot: null,
		tokens: 0,
		isJoinedToProjects: [],
		isUserVerified: false,
	});

	const dispatch = useAppDispatch();

	useEffect(() => {
		const fetchUserInfo = async () => {
			const docRef = doc(db, "users", userId);
			const docSnap = await getDoc(docRef);
			try {
				if (docSnap.exists()) {
					const userData = docSnap.data();
					try {
						const userName = userData.username ?? "";
						setUserInfo((prevState) => ({ ...prevState, userName }));
					} catch (error) {
						console.error("Error handling 'userName' data:", error);
					}

					try {
						const userIsJoinedToProjects = userData.isJoinedToProjects ?? [];
						setUserInfo((prevState) => ({
							...prevState,
							isJoinedToProjects: [...userIsJoinedToProjects],
						}));
						dispatch(setIsJoinedToProjects(userIsJoinedToProjects));
						localStorage.setItem(
							"userJoinedToProjects",
							userIsJoinedToProjects
						);
					} catch (error) {
						console.error("Error handling 'isJoinedToProject' data:", error);
					}

					try {
						const isUserVerified = userData.isUserVerified ?? false;
						setUserInfo((prevState) => ({ ...prevState, isUserVerified }));
						dispatch(setHasUserVerified(isUserVerified));
					} catch (error) {
						console.error("Error handling 'isUserVerified' data:", error);
					}

					try {
						const tokens = userData.tokens ?? 0;
						setUserInfo((prevState) => ({ ...prevState, tokens }));
						if (updateOnRerender === undefined) {
							dispatch(setTokens(tokens));
						}
					} catch (error) {
						console.error("Error handling 'Tokens' data:", error);
					}

					try {
						const photoURL = userData.photoURL ?? "";
						setUserInfo((prevState) => ({ ...prevState, photoURL }));
					} catch (error) {
						console.error("Error handling 'photoURL' data:", error);
					}

					try {
						const about = userData.about ?? "";
						setUserInfo((prevState) => ({ ...prevState, about }));
					} catch (error) {
						console.error("Error handling 'about' data:", error);
					}

					try {
						const interests = userData.interests ?? [];
						setUserInfo((prevState) => ({ ...prevState, interests }));
					} catch (error) {
						console.error("Error handling 'interests' data:", error);
					}

					try {
						const spot = userData.favoriteSpot ?? null;
						setUserInfo((prevState) => ({ ...prevState, spot }));
					} catch (error) {
						console.error("Error handling 'spot' data:", error);
					}
				}
			} catch (error) {
				console.error("Error fetching user info:", error);
				setUserInfo({
					userName: "",
					photoURL: "",
					about: "",
					interests: [],
					spot: null,
					tokens: 0,
					isJoinedToProjects: [],
					isUserVerified: false,
				});
			}
		};
		fetchUserInfo();
	}, [userId]);
	return userInfo;
};

export default useUserProfileInfo;
